@import "~react-image-gallery/styles/scss/image-gallery.scss";

.image-gallery {
	@include vendor-prefix("user-select", none);
	-webkit-tap-highlight-color: transparent;

	.image-gallery-thumbnails-wrapper {
		display: none;
	}

	&.fullscreen-modal {
		background: white;
		bottom: 0;
		height: 100%;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 9999;

		.image-gallery-thumbnails-wrapper {
			display: block;
		}

		.image-gallery-content {
			top: 48%;
			transform: translateY(-50%);
			&.fullscreen {
				background: white;

				.image-gallery-slide {
					background: white;
				}
			}
		}

		img {
			height: 550px;
			width: 100%;
		}
		.image-gallery-fullscreen-button {
			top: 50px;
			bottom: auto;
			right: 20px;
		}
		.image-gallery-slide {
			img {
				width: 100%;
				height: 700px;
				object-fit: contain;
			}
			@media (max-width: 767.98px) {
				img {
					height: 500px;
				}
			}
		}
	}
}

// current image
.image-gallery-slide {
	background: white;
	left: 0;
	right: 0;
	position: absolute;
	top: 0;
	width: 100%;

	&.center {
		position: relative;
	}

	img {
		width: 100%;
		height: 400px;
		object-fit: contain;
	}
}

// thumbnails wrapper
.image-gallery-thumbnail {
	margin: 5px;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
	border: 3px solid transparent;

	img {
		height: 65px;
		width: 65px;
	}

	&.active,
	&:hover,
	&:focus {
		outline: none;
		border: 3px solid #1b5c71;
	}
}

// thumbnail images
.image-gallery-thumbnail img {
	max-height: 65px;
	object-fit: contain;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
	color: transparent;
	font-size: 3em;
	padding: 50px 15px;
	top: 50%;
	transform: translateY(-50%);
}

.image-gallery-left-nav:hover {
	color: transparent;
	cursor: w-resize;
}

.image-gallery-right-nav:hover {
	color: transparent;
	cursor: e-resize;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button,
.image-gallery-left-nav,
.image-gallery-right-nav {
	appearance: none;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	outline: none;
	position: absolute;
	z-index: 4;

	&::before {
		color: white;
		line-height: 0.7;
		text-shadow: 0 2px 2px lighten(black, 10%);
		transition: color 0.2s ease-out;
	}

	&:hover::before {
		color: whitesmoke;
		transform: scale(1.1);
	}

	&:focus::before {
		outline: none;
	}
}
