// ============== PL / PDP below ==============
// ribbon -> SALE
.ribbon-sale {
	position: absolute;
	left: -5px;
	top: -5px;
	z-index: 19;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}
.ribbon-sale span {
	font-size: 10px;
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 20px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	width: 100px;
	display: block;
	background: #f58401;
	background: linear-gradient(#ff8901 0%, #db7603 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 19px;
	left: -21px;
}
.ribbon-sale span:before {
	content: "";
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #f58401;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #f58401;
}
.ribbon-sale span:after {
	content: "";
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #f58401;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #f58401;
}

// =====================================================
// ribbon -> NEU
.ribbon-neu {
	position: absolute;
	left: -5px;
	top: -5px;
	z-index: 19;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}
.ribbon-neu span {
	font-size: 10px;
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 20px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	width: 100px;
	display: block;
	background: #01b2c4;
	background: linear-gradient(#01b2c4 0%, #028a99 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 19px;
	left: -21px;
}
.ribbon-neu span::before {
	content: "";
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #01b2c4;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #01b2c4;
}
.ribbon-neu span::after {
	content: "";
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #01b2c4;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #01b2c4;
}

// =====================================================
// ribbon -> TOP
.ribbon-top {
	position: absolute;
	left: -5px;
	top: -5px;
	z-index: 19;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}
.ribbon-top span {
	font-size: 10px;
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 20px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	width: 100px;
	display: block;
	background: #79a70a;
	background: linear-gradient(#16cb5f 0%, #0a9742 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 19px;
	left: -21px;
}
.ribbon-top span::before {
	content: "";
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #79a70a;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #79a70a;
}
.ribbon-top span::after {
	content: "";
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #79a70a;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #79a70a;
}

// =====================================================
// ribbon -> TESTSIEGER
.ribbon-testsieger {
	position: absolute;
	left: -5px;
	top: -5px;
	z-index: 19;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}
.ribbon-testsieger span {
	font-size: 10px;
	font-weight: bold;
	color: white;
	text-transform: uppercase;
	text-align: center;
	line-height: 20px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	width: 100px;
	display: block;
	background: #0198d5;
	background: linear-gradient(#0198d5 0%, #0198d5 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 19px;
	left: -21px;
}
.ribbon-testsieger span::before {
	content: "";
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #0198d5;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #0198d5;
}
.ribbon-testsieger span::after {
	content: "";
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #0198d5;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #0198d5;
}

// =====================================================
// ribbon -> NEU
.ribbon-neu {
	position: absolute;
	left: -5px;
	top: -5px;
	z-index: 19;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}
.ribbon-neu span {
	font-size: 10px;
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 20px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	width: 100px;
	display: block;
	background: #01b2c4;
	background: linear-gradient(#01b2c4 0%, #028a99 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 19px;
	left: -21px;
}
.ribbon-neu span::before {
	content: "";
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #01b2c4;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #01b2c4;
}
.ribbon-neu span::after {
	content: "";
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #01b2c4;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #01b2c4;
}

// =====================================================
// ribbon -> AKTION
.ribbon-aktion {
	position: absolute;
	left: -5px;
	top: -5px;
	z-index: 19;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}
.ribbon-aktion span {
	font-size: 10px;
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 20px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	width: 100px;
	display: block;
	background: #f58401;
	background: linear-gradient(#ff8901 0%, #db7603 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 19px;
	left: -21px;
}
.ribbon-aktion span::before {
	content: "";
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #f58401;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #f58401;
}
.ribbon-aktion span::after {
	content: "";
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #f58401;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #f58401;
}

// ============== PDP ==============

// ribbon -> SALE
.ribbon-sale-pdp {
	position: absolute;
	left: -7px;
	top: -8px;
	z-index: 19;
	overflow: hidden;
	width: 150px;
	height: 150px;
	text-align: right;
}
.ribbon-sale-pdp span {
	font-size: 12px;
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 25px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	width: 150px;
	display: block;
	background: black;
	background: linear-gradient(rgb(87, 87, 87) 0%, rgb(20, 20, 20) 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 35px;
	left: -29px;
}
.ribbon-sale-pdp span:before {
	content: "";
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid black;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid black;
}
.ribbon-sale-pdp span:after {
	content: "";
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid black;
	border-bottom: 3px solid transparent;
	border-top: 3px solid black;
}
//
//
//
//
//
//
//
// =====================================================
// ribbon -> NEU
.ribbon-neu-pdp {
	position: absolute;
	left: -7px;
	top: -8px;
	z-index: 19;
	overflow: hidden;
	width: 150px;
	height: 150px;
	text-align: right;
}
.ribbon-neu-pdp span {
	font-size: 12px;
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 25px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	width: 150px;
	display: block;
	background: #01b2c4;
	background: linear-gradient(#01b2c4 0%, #028a99 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 35px;
	left: -29px;
}
.ribbon-neu-pdp span::before {
	content: "";
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #01b2c4;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #01b2c4;
}
.ribbon-neu-pdp span::after {
	content: "";
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #01b2c4;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #01b2c4;
}

// =====================================================
// ribbon -> TESTSIEGER
.ribbon-testsieger {
	position: absolute;
	left: -7px;
	top: -8px;
	z-index: 19;
	overflow: hidden;
	width: 150px;
	height: 150px;
	text-align: right;
}
.ribbon-testsieger span {
	font-size: 12px;
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 25px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	width: 150px;
	display: block;
	background: #0198d5;
	background: linear-gradient(#0198d5 0%, #0198d5 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 35px;
	left: -29px;
}
.ribbon-testsieger span::before {
	content: "";
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #0198d5;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #0198d5;
}
.ribbon-testsieger span::after {
	content: "";
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #0198d5;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #0198d5;
}

// =====================================================
// ribbon -> TOP
.ribbon-top-pdp {
	position: absolute;
	left: -7px;
	top: -8px;
	z-index: 19;
	overflow: hidden;
	width: 150px;
	height: 150px;
	text-align: right;
}
.ribbon-top-pdp span {
	font-size: 12px;
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 25px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	width: 150px;
	display: block;
	background: #16cb5f;
	background: linear-gradient(#16cb5f 0%, #0a9742 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 35px;
	left: -29px;
}
.ribbon-top-pdp span::before {
	content: "";
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #067e36;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #067e36;
}
.ribbon-top-pdp span::after {
	content: "";
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #067e36;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #067e36;
}

// =====================================================
// ribbon -> AKTION
.ribbon-aktion-pdp {
	position: absolute;
	left: -7px;
	top: -8px;
	z-index: 19;
	overflow: hidden;
	width: 150px;
	height: 150px;
	text-align: right;
}
.ribbon-aktion-pdp span {
	font-size: 12px;
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 25px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	width: 150px;
	display: block;
	background: #f58401;
	background: linear-gradient(#ff8901 0%, #db7603 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 35px;
	left: -29px;
}
.ribbon-aktion-pdp span:before {
	content: "";
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #f58401;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #f58401;
}
.ribbon-aktion-pdp span:after {
	content: "";
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #f58401;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #f58401;
}
