@import "./badge.scss";

// Only global styles should go here!
body,
html {
	margin: 0;
	font-family: "Mulish", sans-serif;
	line-height: "1.4";
	font-weight: 300;
	font-size: 1rem;
	scroll-behavior: smooth;
	letter-spacing: 0.015em;
	min-height: 100%;
}

// ========== fonts ==========
.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
	color: "#263739";
}
@media (max-width: 991px) {
	.h1,
	h1 {
		font-size: 1rem;
	}
}

a {
	color: #168db5;
}

.p,
p {
	line-height: 1.5;
	font-family: "Mulish", sans-serif;
	font-weight: 300;
	color: #505e5f;
}

div,
.div {
	line-height: 1.5;
	font-family: "Mulish", sans-serif;
	font-weight: 300;
	color: "#263739";
}

.bold {
	font-weight: 700;
}

.small {
	font-size: 80%;
}

.light {
	font-weight: "200";
}

.uppercase {
	text-transform: uppercase;
}

.link {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}

.scrollX-no-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.vertical-align-border {
	height: 1px;
	border: 0;
	background-image: linear-gradient(
		(to right, rgba(0, 0, 0, 0)),
		rgba(0, 0, 0, 0.75),
		rgba(0, 0, 0, 0)
	);
}

// ribbon

.ribbon {
	position: absolute;
	left: -5px;
	top: -5px;
	z-index: 19;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}
.ribbon span {
	font-size: 10px;
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 20px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	width: 100px;
	display: block;
	background: #f58401;
	background: linear-gradient(#ff8901 0%, #db7603 100%);
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 19px;
	left: -21px;
}
.ribbon span:before {
	content: "";
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #f58401;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #f58401;
}
.ribbon span:after {
	content: "";
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #f58401;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #f58401;
}

// pdp divider
.arrowSeperator {
	display: block;
	text-align: center;
	border: 1px solid #ddd;
	width: 100%;
	height: 0;
	position: relative;
	transform: rotate(180deg);
}

.arrowSeperator:before {
	content: "";
	width: 0;
	height: 0;
	border-left: 15px solid transparent; // this is the length of the side of the arrow
	border-right: 15px solid transparent; // this is the length of the side of the arrow
	border-bottom: 15px solid #ddd; // set to same color as the line, this is the height of the arrow
	position: absolute;
	bottom: 100%;
	left: 50%; // used to center arrow
	right: 50%; // used to center arrow
	margin: 0 -15px; // left & right offset need to match the width of the arrow to center arrow
	z-index: 1;
}

// this arrow lays over the other arrow
// offset slightly to show a line
.arrowSeperator:after {
	content: "";
	width: 0;
	height: 0;
	border-left: 15px solid transparent; // this is the length of the side of the arrow
	border-right: 15px solid transparent; // this is the length of the side of the arrow
	border-bottom: 15px solid #fff; // set to color of background, this is the height of the arrow
	position: absolute;
	bottom: 100%;
	left: 50%; // used to center arrow
	right: 50%; // used to center arrow
	margin: 0 -15px -2px -15px; // left & right offset need to match the width of the arrow to center arrow
	z-index: 2;
}

.category-description-wrapper {
	table {
		display: block;
		max-width: 100%;
		overflow-x: scroll;

		td {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	img {
		max-width: 100%;
	}
}

.deliveryTimeBTFAnimation {
	@keyframes DeliveryTime {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	animation: DeliveryTime 0.5s ease-in-out;
}

// navbar dropdown
.nav-dropdown {
	position: relative;
	display: inline-block;
	padding: 8px 10px 8px 10px;
}

.nav-dropdown-content {
	display: none;
	position: absolute;
	background-color: #fff;
	min-width: 270px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 5;
	font-size: 1.2rem;
	left: 0;
	top: 40px;
	padding: 10px 0px;
}

.nav-dropdown:hover .nav-dropdown-content {
	display: block;
}

.nav-dropdown:hover {
	background: #01b2c4;
}
