.cart-dropdown {
	position: relative;
	display: inline-block;
	z-index: 99;
}

.cart-dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	width: 270px;
	right: 0px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	border: 1px solid whitesmoke;
	border-radius: 9px;
	z-index: 1;
}

.cart-dropdown:hover .cart-dropdown-content {
	display: block;
}

.cart-dropdown:hover .dropbtn {
	transition: 0.3s;
	background-color: #4691ce;
}
